<template>
    <div
        @click="$emit('click')"
        :class="{
            'notifications-bell': true,
            hasNotif: notificationsCount > 0
        }"
    >
        <font-awesome-icon :icon="['fal', 'bell']" />
        <span v-if="notificationsCount > 0">
            {{ notificationsCount > 99 ? '...' : notificationsCount }}
        </span>
    </div>
</template>

<script>
export default {
    computed: {
        notificationsCount() {
            return this.$store.state.nav.notificationsCount
        }
    }
}
</script>
